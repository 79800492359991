* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body,
#root {
	height: 100%;
}

button,
input {
	font-family: inherit;
}
