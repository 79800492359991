.Project-ImagesSlider {
	width: 100%;
}

.Project-Title {
	font-size: 1rem;
	font-weight: 500;
	margin-top: 1rem;
	color: #002855;
}

.Project-Description {
	margin-top: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #001233;
}

.Project-Technologies {
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
}

.Project-TechnologyChip {
	margin-right: 8px;
	margin-bottom: 8px;
}

@media only screen and (min-width: 650px) {
	.Project {
		display: grid;
		grid-template-columns: minmax(0, 0.7fr) minmax(0, 1fr);
		column-gap: 1rem;
	}

	.Project-Title {
		margin-top: 0;
		padding-top: 0.5rem;
	}
}
