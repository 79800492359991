.Company-NameAndIcon {
	display: grid;
	grid-template-columns: 24px minmax(0, 1fr);
	align-items: center;
	column-gap: 8px;
	margin-bottom: 1rem;
}

.Company-Icon {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	object-fit: cover;
	border: 1px solid #eeeeee;
}

.Company-Name {
	font-size: 1.2rem;
	font-weight: 500;
	color: #33415c;
}

.Company .Project {
	margin-bottom: 1rem;
}

.Company .Project:last-child {
	margin-bottom: 0;
}
