@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);


:root {
	--primary-font: 'Roboto', arial, sans-serif;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body,
#root {
	height: 100%;
}

button,
input {
	font-family: inherit;
}

body {
	font-family: var(--primary-font);
}

.App {
	min-height: 100%;
	overflow: auto;
	padding: 16px;
	display: flex;
	justify-content: center;
	background: rgb(0, 40, 85);
	background: linear-gradient(
		180deg,
		rgba(0, 40, 85, 1) 33%,
		rgba(0, 24, 69, 1) 66%,
		rgba(0, 18, 51, 1) 100%
	);
}

.CVDocument {
	width: 8.3in;
	background-color: white;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.219);
	border-radius: 10px;
}

.CVDocument-SectionSeparator {
	width: 100%;
	height: 2px;
	background-color: #eeeeee;
}

.DocumentHeader {
	display: grid;
	grid-template-rows: 128px auto;
	grid-row-gap: 16px;
	row-gap: 16px;
	padding: 1rem;
	position: relative;
}

.DocumentHeader-ProfileImage {
	width: 128px;
	height: 128px;
	border-radius: 50%;
	object-fit: cover;
	border: 4px solid #144552;
	justify-self: center;
}

.DocumentHeader-Name {
	color: #002855;
	font-size: 1.4rem;
	text-align: center;
	font-weight: bold;
}

.DocumentHeader-TechnologiesContainer {
	display: flex;
	align-items: center;
	margin-top: 16px;
	flex-wrap: wrap;
	justify-content: center;
}

.DocumentHeader-TechnologyChip {
	margin-right: 8px;
	margin-bottom: 8px;
}

.DocumentHeader-ExternalLinks {
	display: grid;
	grid-template-columns: repeat(2, 32px);
	grid-column-gap: 8px;
	-webkit-column-gap: 8px;
	        column-gap: 8px;
	justify-content: center;
	position: absolute;
	top: 8px;
	right: 8px;
}

.DocumentHeader-ExternalLink {
	width: 32px;
	height: 32px;
}

.DocumentHeader-ExternalLink:hover {
	cursor: pointer;
}

.DocumentHeader-ExternalLink-Image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

@media only screen and (min-width: 500px) {
}

.TechnologyChip {
	display: grid;
	grid-template-columns: 24px minmax(0, 1fr);
	align-items: center;
	grid-column-gap: 8px;
	-webkit-column-gap: 8px;
	        column-gap: 8px;
	border: 1px solid #979dac7c;
	padding: 4px;
	border-radius: 16px;
}

.TechnologyChip-Image {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	object-fit: cover;
}

.TechnologyChip-Text {
	font-size: 1rem;
	color: #33415C;
}

.ExperienceSection {
	padding: 1rem;
}

.ExperienceSection-Title {
	font-size: 1.4rem;
	color: #023E7D;
	font-weight: bold;
	margin-bottom: 1rem;
}

.ExperienceSection-CompanySeparator {
	width: 100%;
	height: 2px;
	background-color: #eeeeee;
	margin: 1rem 0;
}

.Company-NameAndIcon {
	display: grid;
	grid-template-columns: 24px minmax(0, 1fr);
	align-items: center;
	grid-column-gap: 8px;
	-webkit-column-gap: 8px;
	        column-gap: 8px;
	margin-bottom: 1rem;
}

.Company-Icon {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	object-fit: cover;
	border: 1px solid #eeeeee;
}

.Company-Name {
	font-size: 1.2rem;
	font-weight: 500;
	color: #33415c;
}

.Company .Project {
	margin-bottom: 1rem;
}

.Company .Project:last-child {
	margin-bottom: 0;
}

.Project-ImagesSlider {
	width: 100%;
}

.Project-Title {
	font-size: 1rem;
	font-weight: 500;
	margin-top: 1rem;
	color: #002855;
}

.Project-Description {
	margin-top: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #001233;
}

.Project-Technologies {
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
}

.Project-TechnologyChip {
	margin-right: 8px;
	margin-bottom: 8px;
}

@media only screen and (min-width: 650px) {
	.Project {
		display: grid;
		grid-template-columns: minmax(0, 0.7fr) minmax(0, 1fr);
		grid-column-gap: 1rem;
		-webkit-column-gap: 1rem;
		        column-gap: 1rem;
	}

	.Project-Title {
		margin-top: 0;
		padding-top: 0.5rem;
	}
}

.ImageSlider {
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	background-color: #eeeeee;
	position: relative;
}

.ImageSlider-Container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ImageSlider-CurrentImageContainer {
	width: 100%;
	height: 100%;
}

.ImageSlider-ButtonToOpenDialog {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	border: none;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ImageSlider-ButtonToOpenDialog:hover {
	cursor: pointer;
}

.ImageSlider-VisibilityIcon {
	width: 42px;
}

.ImageSlider-CurrentImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
}

.ImageSliderDialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
}

.ImageSliderDialog-CloseButton {
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	width: 48px;
	height: 48px;
	border: none;
	border-bottom-left-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
}

.ImageSliderDialog-CloseButton:hover {
	cursor: pointer;
}

.ImageSliderDialog-BeforeButton,
.ImageSliderDialog-NextButton {
	position: absolute;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
}

.ImageSliderDialog-BeforeButton:hover,
.ImageSliderDialog-NextButton:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.5);
}

.ImageSliderDialog-BeforeIcon,
.ImageSliderDialog-NextIcon {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	width: 32px;
}

.ImageSliderDialog-BeforeButton {
	left: 0;
	top: 0;
	width: 32px;
	height: 100%;
}

.ImageSliderDialog-BeforeButton:hover .ImageSliderDialog-BeforeIcon {
	background-color: transparent;
}

.ImageSliderDialog-NextButton {
	right: 0;
	top: 0;
	width: 32px;
	height: 100%;
}

.ImageSliderDialog-NextButton:hover .ImageSliderDialog-NextIcon {
	background-color: transparent;
}

.ImageSliderDialog-ImageSelector {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, 32px);
	justify-content: center;
	padding-bottom: 8px;
}

.ImageSliderDialog-ImageSelectorButton {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: none;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ImageSliderDialog-ImageSelectorButton::before {
	content: ' ';
	width: 8px;
	height: 8px;
	background-color: #888787;
	border-radius: 50%;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.ImageSliderDialog-ImageSelectorButton:hover {
	background-color: rgba(0, 0, 0, 0.2);
	cursor: pointer;
}

.ImageSliderDialog-ImageSelectorButton_selected {
	background-color: rgba(0, 0, 0, 0.5) !important;
}

.ImageSliderDialog-ImageSelectorButton_selected::before {
	background-color: white;
}

.ImageSliderDialog-CurrentImage {
	max-width: 100%;
	max-height: 100%;
}

@media only screen and (min-width: 650px) {
	.ImageSliderDialog-CloseButton {
		width: 96px;
		height: 96px;
	}

	.ImageSliderDialog-CloseIcon {
		width: 64px;
	}

	.ImageSliderDialog-BeforeIcon,
	.ImageSliderDialog-NextIcon {
		width: 64px;
	}

	.ImageSliderDialog-BeforeButton {
		width: 64px;
	}

	.ImageSliderDialog-NextButton {
		width: 64px;
	}
}

