.CVDocument {
	width: 8.3in;
	background-color: white;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.219);
	border-radius: 10px;
}

.CVDocument-SectionSeparator {
	width: 100%;
	height: 2px;
	background-color: #eeeeee;
}
