.ExperienceSection {
	padding: 1rem;
}

.ExperienceSection-Title {
	font-size: 1.4rem;
	color: #023E7D;
	font-weight: bold;
	margin-bottom: 1rem;
}

.ExperienceSection-CompanySeparator {
	width: 100%;
	height: 2px;
	background-color: #eeeeee;
	margin: 1rem 0;
}
